import React, {useEffect, useState} from 'react';
import SwaggerUIComponent from '../SwaggerUIComponent/SwaggerUIComponent';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import yaml from 'js-yaml';
import {
    BodyContainer,
    ContentSection,
    GapSection,
    AnchorMenuSection,
    ContentContainer,
    HorizontalLine,
    Paragraph,
    BoldFont,
    SemiBoldFont,
    H4Heading,
    H5BoldHeading,
    H6BoldHeading,
    CodeInContent,
    UnorderedList,
    ListItem,
    StartingSpace,
    Table4Col,
    Table4ColHead,
    Table4ColCellBold,
    TableCell,
    TableCellFlex,
    Table5Col,
    Table5ColHead,
    Table5ColCellBold
} from './APIDocsComponent.styled';
import TextLink from '@snsw/react-component-library/build/Components/TextLink/TextLink';
import {DashboardHeading} from '../DashboardComponent/DashboardComponent.styled';
import VariablesRequestCode from './VariablesRequestCode';
import VariablesResponseCode from './VariablesResponseCode';
import ConditionalTextCode from './ConditionalTextCode';
import AuthenticationRequestCode from './AuthenticationRequestCode';
import AuthenticationResponseCode from './AuthenticationResponseCode';
import AttachmentRequestCode from './AttachmentRequestCode';
import AttachmentResponseCode from './AttachmentResponseCode';
import CodeSnippetComponent from './CodeSnippetComponent';
import AnchorMenuComponent from '../CommonComponents/AnchorMenuComponent/AnchorMenuComponent';
import {scrollToTop} from '../GlobalStateComponent/GlobalState';

const APIDocsComponent = () => {

    const anchorMenuMetaData = {
        title: 'On this page',
        data: [
            {id: 'menu-introduction', title: 'Introduction', level: 0},
            {id: 'menu-step1', title: 'Step 1: Create Notification', level: 1},
            {id: 'menu-step1-notification-codes', title: 'Notification codes', level: 2},
            {id: 'menu-step1-variables', title: 'Variables', level: 2},
            {id: 'menu-step1-conditional-text', title: 'Conditional text', level: 2},
            {id: 'menu-step2', title: 'Step 2: Get Authorisation', level: 1},
            {id: 'menu-step2-internal-team', title: 'Service NSW teams', level: 2},
            {id: 'menu-step2-external-team', title: 'Non-Service NSW teams', level: 2},
            {id: 'menu-step3', title: 'Step 3: Make API Request', level: 1},
            {id: 'menu-step3-environment', title: 'Environment', level: 2},
            {id: 'menu-step3-authentication', title: 'Authentication', level: 2},
            {id: 'menu-step4', title: 'Step 4: Send your notification', level: 1},
            {id: 'menu-step4-endpoints', title: 'Endpoints', level: 2},
            {id: 'menu-reporting', title: 'Reporting', level: 1},
            {id: 'menu-preference', title: 'Preferences', level: 1},
            {id: 'menu-help', title: 'Help', level: 1}
        ]
    };

    const [swaggerSpec, setSwaggerSpec] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        scrollToTop();
        const fetchSpec = async () => {
            try {
                console.log('Retrieving swagger.yaml');
                const response = await fetch('../../digital-notifications-swagger.yaml');
                if (!response.ok) {
                    console.log('Response is non OK. Error retrieving swagger.yaml');
                    throw new Error(`Failed to fetch: ${response.statusText}`);
                }
                const specData = await response.text();
                const parsedSpec = yaml.load(specData);
                setSwaggerSpec(parsedSpec);
                console.log('Swagger spec loaded successful');
            } catch (err) {
                console.log('Error retrieving swagger.yaml: '+err.message);
                setError(err.message);
            }
        };
        fetchSpec();
    }, []);

    const filterSpec = (tag) => {
        //Making a deep copy so we should not end up changing the swaggerSpec
        const filteredSpec = JSON.parse(JSON.stringify(swaggerSpec));
        Object.keys(filteredSpec.paths).forEach((path) => {
            Object.keys(filteredSpec.paths[path]).forEach((method) => {
                if (!filteredSpec.paths[path][method].tags.includes(tag) || filteredSpec.paths[path][method].deprecated) {
                    delete filteredSpec.paths[path][method];
                }
            });
            if (Object.keys(filteredSpec.paths[path]).length === 0) {
                delete filteredSpec.paths[path];
            }
        });

        // Remove unnecessary sections
        delete filteredSpec.info;
        delete filteredSpec.tags;
        return { ...filteredSpec};
    };

    return (
        <CustomTableContainer>
            <DashboardHeading id={ `${anchorMenuMetaData.data[0].id}` }>API Docs</DashboardHeading>
            <BodyContainer>
                <ContentSection>
                    <ContentContainer>
                        <div>
                            <Paragraph>The Service NSW Digital Notifications API provides Service NSW teams and NSW
                                Government agency partners with API access to send and track notifications across
                                our four secure digital channels including Email, SMS, Mobile app push and MyServiceNSW
                                inbox.</Paragraph>
                            <Paragraph>The API enables teams to connect with our sending platform and trigger
                                notifications
                                based on their transactions and data.</Paragraph>
                        </div>
                        <HorizontalLine/>
                        <div id={ `${anchorMenuMetaData.data[1].id}` }>
                            <H4Heading>Step 1: Create your notification in the portal</H4Heading></div>
                        <div>
                            <Paragraph>
                                To use the Digital Notifications API, you must first create your notification in the
                                portal.
                                The portal allows you to create, edit, and manage your notifications in one place.
                                {/* eslint-disable-next-line max-len */}
                                It also stores and configures the notification <BoldFont>channel</BoldFont>, <BoldFont>content</BoldFont> and <BoldFont>variable</BoldFont> information,
                                allowing both technical and non-technical team members to make edits as
                                needed.</Paragraph>
                            <UnorderedList>
                                <ListItem>
                                    Navigate to the&nbsp;
                                    <TextLink target={ '_blank' }
                                        href={ '/dashboard' }>
                                        Dashboard</TextLink> > click&nbsp;
                                    <TextLink target={ '_blank' }
                                        href={ '/add-notification/service/:serviceCode?from=dashboard' }>
                                        Create Notification</TextLink> button and follow the prompts
                                </ListItem>
                            </UnorderedList>
                        </div>
                        <div id={ `${anchorMenuMetaData.data[2].id}` }><H6BoldHeading>Get notification
                            codes</H6BoldHeading></div>
                        <div>
                            <Paragraph>
                                After creating the notification, you will be provided with the
                                engineering codes needed to proceed with the API configuration.
                                You’ll need the <BoldFont>Service Code</BoldFont> and
                                the <BoldFont>Notification Code</BoldFont>.</Paragraph>
                        </div>
                        <div id={ `${anchorMenuMetaData.data[3].id}` }>
                            <H6BoldHeading>Adding variables and personalisation</H6BoldHeading></div>
                        <div>
                            <Paragraph>
                                You can personalise your notification by using variables. When creating your
                                notification in
                                the portal, use the code <CodeInContent>{'{{request.VariableName}}'}</CodeInContent> to
                                add a custom variable within your message in the content area.</Paragraph>
                            <Paragraph><SemiBoldFont>For example:</SemiBoldFont></Paragraph>
                            <Paragraph>Your <CodeInContent>{'{{request.ProgramName}}'}</CodeInContent> grant
                                application has been approved.</Paragraph>
                            <Paragraph>Each time you send the message, you can replace the variable text with values you
                                provide in
                                your API request.</Paragraph>
                        </div>
                        <div>
                            <Paragraph>
                                If you are sending to an authenticated MyAccount customer, you can personalise messages
                                using values that are already stored in the customer’s account, these predefined
                                MyAccount
                                customer data variables include:</Paragraph>
                            <UnorderedList>
                                <ListItem>
                                    <CodeInContent>{'{{customer.firstName}}'}</CodeInContent> = Customer’s first
                                    name</ListItem>
                                <ListItem>
                                    <CodeInContent>{'{{customer.familyName}}'}</CodeInContent> = Customer’s last
                                    name</ListItem>
                                <ListItem>
                                    <CodeInContent>{'{{customer.email}}'}</CodeInContent> = Customer’s email address
                                    used in their account</ListItem>
                            </UnorderedList>
                            <Paragraph style={ {marginTop: '10px'} }>To create a custom variable:</Paragraph>
                            <UnorderedList>
                                <ListItem>
                                    Use the following naming convention when creating your notification content in the
                                    portal <CodeInContent>{'{{request.customVariableName}}'}</CodeInContent></ListItem>
                                <ListItem>This custom variable must be provided in the sending API.</ListItem>
                                <ListItem>The maximum number of variables supported by this endpoint is 50.</ListItem>
                            </UnorderedList>
                        </div>
                        <CodeSnippetComponent component={ VariablesRequestCode } name='code-variables-request'/>
                        <CodeSnippetComponent component={ VariablesResponseCode } name='code-variables-response'/>
                        <div id={ `${anchorMenuMetaData.data[4].id}` }><H6BoldHeading>Conditional text</H6BoldHeading>
                        </div>
                        <div>
                            <Paragraph>
                                You can add specific content to tailor your message for different audiences.<br/>
                                For example, if you only wanted to display a sentence to business customers:</Paragraph>
                            <Paragraph><CodeInContent>{'{{#ifequals request.customerType "Business"}}'}</CodeInContent></Paragraph>
                            <Paragraph>To get help, contact your business concierge.</Paragraph>
                            <Paragraph><CodeInContent>{'{{/ifequals}}'}</CodeInContent></Paragraph>
                            <Paragraph>When you send a message to business customers, you can provide the value for your
                                customerType
                                as "Business" to display the optional content, for example:</Paragraph>
                        </div>
                        <CodeSnippetComponent component={ ConditionalTextCode } name='code-conditional-text'/>
                        <HorizontalLine/>
                        <div id={ `${anchorMenuMetaData.data[5].id}` }><H4Heading>Step 2: Get authorisation</H4Heading>
                        </div>
                        <div id={ `${anchorMenuMetaData.data[6].id}` }><H5BoldHeading>For Service NSW
                            Teams:</H5BoldHeading></div>
                        <><H6BoldHeading>Configure the the apigee app</H6BoldHeading></>
                        <div>
                            <Paragraph>
                                <BoldFont>1.&nbsp;&nbsp;Add app:</BoldFont><br/>
                                <StartingSpace/>Create a new apigee app or use an existing apigee app. You'll be using
                                this app to make the
                                API request.<br/>
                                <BoldFont>2.&nbsp;&nbsp;Add product:</BoldFont><br/>
                                <StartingSpace/>Add the <CodeInContent>tuo-notifications</CodeInContent> product.<br/>
                                <BoldFont>3.&nbsp;&nbsp;Add custom attribute:</BoldFont></Paragraph>
                            <UnorderedList style={ {marginTop: '0rem'} }>
                                <ListItem><BoldFont>Name:</BoldFont>&nbsp;
                                    <CodeInContent>notifications.authorized_service_codes</CodeInContent>.</ListItem>
                                <ListItem><BoldFont>Value:</BoldFont>&nbsp;
                                    Add your <BoldFont>SERVICE CODE</BoldFont> here.
                                    &nbsp;You'll find your service code in the portal or in the
                                    overview email sent after creating your notification.</ListItem>
                                <ListItem>If there are already other service codes in this field, add the new one at the
                                    end,
                                    separated by commas.</ListItem>
                                <ListItem>This custom attribute will allow you to send the notifications under or
                                    associated with that
                                    service code.</ListItem>
                            </UnorderedList>
                            <Paragraph>If you do not have access to apigee, you'll need to request access with the
                                Service NSW API team.</Paragraph>
                        </div>
                        <div id={ `${anchorMenuMetaData.data[7].id}` }>
                            <H5BoldHeading>For non Service NSW organisations:</H5BoldHeading></div>
                        <div>
                            <Paragraph>The Digital Notifications engineering team will configure the app on your behalf
                                and provide you with
                                an <BoldFont>API Key</BoldFont> and an <BoldFont>API Secret</BoldFont>.</Paragraph>
                        </div>
                        <HorizontalLine/>
                        <div id={ `${anchorMenuMetaData.data[8].id}` }><H4Heading>Step 3: Make your API
                            request</H4Heading></div>
                        <div id={ `${anchorMenuMetaData.data[9].id}` }><H5BoldHeading>Select environment</H5BoldHeading>
                        </div>
                        <div>
                            <Paragraph>The Service NSW Digital Notifications API provides Service NSW teams and NSW
                                Government agency
                                partners with API access to send and track notifications across our four secure digital
                                channels
                                including Email, SMS, Mobile app push and MyServiceNSW inbox.</Paragraph>
                            <Paragraph>The API enables teams to connect with our sending platform and trigger
                                notifications
                                based on their transactions and data.</Paragraph>
                            <Paragraph>When you have tested the notification in PSM / lower environments let our team
                                know
                                and we will push your notification to Production.</Paragraph>
                        </div>
                        <Table4Col>
                            <Table4ColHead>Environment</Table4ColHead>
                            <Table4ColHead style={ {background: '#E9F3FA'} }>PSM</Table4ColHead>
                            <Table4ColHead style={ {background: '#D6E8F3'} }>Lower Environments</Table4ColHead>
                            <Table4ColHead style={ {background: '#DAF6DC'} }>Production</Table4ColHead>
                            <Table4ColCellBold style={ {minHeight: '107px'} }>When to use</Table4ColCellBold>
                            <TableCell>When a notification is created in the portal, it is created in PSM.</TableCell>
                            <TableCell>To test notifications in a lower environment, let our team know and we can push
                                the
                                notification to the required environment.
                            </TableCell>
                            <TableCell>After testing your notification let our team know and we’ll push it to the live
                                production environment, ready for sending.
                            </TableCell>
                            <Table4ColCellBold style={ {minHeight: '191px'} }>URL</Table4ColCellBold>
                            <TableCell>https://api-psm.g.testservicensw.net/</TableCell>
                            <TableCellFlex>
                                <div><BoldFont>IT1:</BoldFont>&nbsp;https://api-it1.g.testservicensw.net/</div>
                                <div><BoldFont>IT2:</BoldFont>&nbsp;https://api-it2.g.testservicensw.net/</div>
                                <div><BoldFont>IT3:</BoldFont>&nbsp;https://api-it3.g.testservicensw.net/</div>
                                <div><BoldFont>IT4:</BoldFont>&nbsp;https://api-it4.g.testservicensw.net/</div>
                            </TableCellFlex>
                            <TableCell>https://api.g.service.nsw.gov.au/</TableCell>
                        </Table4Col>
                        <div id={ `${anchorMenuMetaData.data[10].id}` }><H5BoldHeading>Authentication</H5BoldHeading>
                        </div>
                        <div>
                            <Paragraph>All requests to the Digital Notifications API must be authenticated by supplying
                                an access token
                                in the <BoldFont>Authorization</BoldFont> header.
                                We use the <CodeInContent>OAuth2</CodeInContent> authentication client credentials flow.</Paragraph>
                        </div>
                        <CodeSnippetComponent component={ AuthenticationRequestCode } name='code-authentication-request'/>
                        <CodeSnippetComponent component={ AuthenticationResponseCode }
                            name='code-authentication-response'/>
                        <div id={ `${anchorMenuMetaData.data[11].id}` }><H4Heading>Step 4: Sending your
                            notification</H4Heading></div>
                        <div id={ `${anchorMenuMetaData.data[12].id}` }><H5BoldHeading>Choose endpoint</H5BoldHeading>
                        </div>
                        <div>
                            <Paragraph>The Service NSW Digital Notifications API provides Service NSW teams and NSW
                                Government
                                agency partners with API access to send and track notifications across our four secure
                                digital channels
                                including Email, SMS, Mobile app push and MyServiceNSW inbox.</Paragraph>
                            <Paragraph>The API enables teams to connect with our sending platform and
                                trigger notifications based on their transactions and data.</Paragraph>
                        </div>
                        <Table5Col>
                            <Table5ColHead>Endpoint</Table5ColHead>
                            <Table5ColHead style={ {background: '#E9F3FA'} }>Individual<br/>(MyAccount)</Table5ColHead>
                            <Table5ColHead style={ {background: '#E9F3FA'} }>Individual<br/>(Guest)</Table5ColHead>
                            <Table5ColHead style={ {background: '#D6E8F3'} }>Batch<br/>(MyAccount)</Table5ColHead>
                            <Table5ColHead style={ {background: '#D6E8F3'} }>Batch<br/>(Guest)</Table5ColHead>
                            <Table5ColCellBold style={ {minHeight: '53px'} }>Notification<br/>type</Table5ColCellBold>
                            <TableCell>Automated</TableCell>
                            <TableCell>Automated</TableCell>
                            <TableCell>Automated</TableCell>
                            <TableCell>Automated</TableCell>
                            <Table5ColCellBold style={ {minHeight: '53px'} }>Path</Table5ColCellBold>
                            <TableCell>/notifications/v2</TableCell>
                            <TableCell>/notifications/v2/guest</TableCell>
                            <TableCell>/notifications/batch/v2</TableCell>
                            <TableCell>/notifications/batch/v2/guest</TableCell>
                            <Table5ColCellBold style={ {minHeight: '71px'} }>Sent to</Table5ColCellBold>
                            <TableCell>Single MyAccount Customer via Customer ID or Xref</TableCell>
                            <TableCell>Single email address or phone number</TableCell>
                            <TableCell>Multiple MyAccount Customers via Customer ID or Xref</TableCell>
                            <TableCell>Multiple email address’ or phone numbers</TableCell>
                            <Table5ColCellBold style={ {minHeight: '53px'} }>Channels<br/>accepted</Table5ColCellBold>
                            <TableCell>Email, SMS, Push, Inbox</TableCell>
                            <TableCell>Email, SMS</TableCell>
                            <TableCell>Email, SMS, Push, Inbox</TableCell>
                            <TableCell>Email, SMS</TableCell>
                            <Table5ColCellBold style={ {minHeight: '53px'} }>Attachments<br/>accepted</Table5ColCellBold>
                            <TableCell>Yes (email only)</TableCell>
                            <TableCell>Yes (email only)</TableCell>
                            <TableCell>No</TableCell>
                            <TableCell>No</TableCell>
                            <Table5ColCellBold style={ {minHeight: '53px'} }>Variables<br/>accepted</Table5ColCellBold>
                            <TableCell>Yes</TableCell>
                            <TableCell>Yes</TableCell>
                            <TableCell>Yes</TableCell>
                            <TableCell>Yes</TableCell>
                        </Table5Col>
                        {(error != null) ? <div><Paragraph>Error loading API docs: {error}</Paragraph></div>
                            : (swaggerSpec != null) ?
                                <SwaggerUIComponent swaggerSpec={ filterSpec('Notification Service') }/>
                                : <></>}
                        <><H5BoldHeading>Adding attachments in your email</H5BoldHeading></>
                        <div>
                            <Paragraph>Ensure the <BoldFont>Email</BoldFont> channel is selected and
                                configured in your notification in the portal.</Paragraph>
                            <UnorderedList>
                                <ListItem>The maximum number of attachments supported by this endpoint is 5.</ListItem>
                            </UnorderedList>
                        </div>
                        <CodeSnippetComponent component={ AttachmentRequestCode } name='code-attachment-request'/>
                        <CodeSnippetComponent component={ AttachmentResponseCode } name='code-attachment-response'/>
                        <HorizontalLine/>
                        <div id={ `${anchorMenuMetaData.data[13].id}` }><H4Heading>Reporting</H4Heading></div>
                        <><H5BoldHeading>Get the status of an individual notification</H5BoldHeading></>
                        <div>
                            <Paragraph><BoldFont>Please use: </BoldFont><CodeInContent>GET
                                /notifications/{'{notificationId}'}
                                /status/v2</CodeInContent></Paragraph>
                            <Paragraph> This will retrieve the status of a previously sent notification by providing the
                                {/* eslint-disable-next-line max-len */}
                                unique notificationId from the <CodeInContent>/notifications/v2</CodeInContent> or <CodeInContent>/notifications/v2/guest</CodeInContent> response.
                            </Paragraph>
                        </div>
                        {(error != null) ? <div><Paragraph>Error loading API docs: {error}</Paragraph></div>
                            : (swaggerSpec != null) ?
                                <SwaggerUIComponent swaggerSpec={ filterSpec('Reporting Service') }/>
                                : <></>}
                        <><H6BoldHeading>Status meanings</H6BoldHeading></>
                        <div>
                            <UnorderedList style={ {marginTop: '0px'} }>
                                <ListItem><BoldFont>IN_PROGRESS:</BoldFont> Notification is processing.</ListItem>
                                <ListItem><BoldFont>RETRY:</BoldFont> Retrying due to a previous issue.</ListItem>
                                <ListItem><BoldFont>BOUNCED (email channel only):</BoldFont>
                                    &nbsp;Customer on spam list, non-delivery.</ListItem>
                                <ListItem><BoldFont>FAILED:</BoldFont>
                                    &nbsp;Sending failed (refer to error message for details).</ListItem>
                                <ListItem><BoldFont>SUCCESS:</BoldFont>
                                    &nbsp;Notification successfully sent.</ListItem>
                                <ListItem><BoldFont>OPENED:</BoldFont>
                                    &nbsp;Customer opened the notification (not applicable to SMS; unreliable with
                                    Email).</ListItem>
                                <ListItem><BoldFont>NO_APP_FOUND:</BoldFont>
                                    &nbsp;Customer lacks the SNSW Mobile app (Mobile App Push channel only).</ListItem>
                                <ListItem><BoldFont>NOT_ATTEMPTED:</BoldFont>
                                    &nbsp;Notification not attempted (e.g customerID is d)</ListItem>
                            </UnorderedList>
                        </div>
                        <HorizontalLine/>
                        <div id={ `${anchorMenuMetaData.data[14].id}` }><H4Heading>Preferences</H4Heading></div>
                        <><H5BoldHeading>Customer consent</H5BoldHeading></>
                        <div>
                            <Paragraph>Customers can provide consent to receive some digital notifications via their
                                SNSW App or
                                the <TextLink target={ '_blank' }
                                href={ 'https://account.service.nsw.gov.au/manage/notification-preferences' }>
                                    MyServiceNSW Account Notification Preferences page</TextLink>.
                                Today, notifications consent is only stored by Service NSW Notifications
                                for the following services:</Paragraph>
                            <UnorderedList>
                                <ListItem>Vehicle Registration reminders (Digital / Paper)</ListItem>
                                <ListItem>Notice of Valuation (Digital / Paper)</ListItem>
                                <ListItem>News and Updates (digital only)</ListItem>
                            </UnorderedList>
                            {/* eslint-disable-next-line max-len */}
                            <Paragraph style={ {marginTop: '10px'} }>Customers are not able to set a preference for
                                notifications that do not require consent, for example notifications that are sent as
                                part of a transaction that the customer has engaged in.</Paragraph>
                            <Paragraph>The below APIs are only applicable to the notification services listed above, for
                                which customers are
                                able to update their consent status.</Paragraph>
                        </div>
                        <><H6BoldHeading>Get a customer’s notification preference status</H6BoldHeading></>
                        <div>
                            <Paragraph><BoldFont>Please use: </BoldFont><CodeInContent>GET /notifications/preferences
                                /{'{customerId}'}/service-consent/{'{serviceCode}'}</CodeInContent></Paragraph>
                            <Paragraph>This will retrieve the current notification consent status for
                                a MyAccount Customer for a given notification service.</Paragraph>
                            <Paragraph>In your request you need to pass values for:</Paragraph>
                            <UnorderedList>
                                <ListItem><BoldFont>CustomerID:</BoldFont> This should be a MyAccount Customer
                                    ID</ListItem>
                                {/* eslint-disable-next-line max-len */}
                                <ListItem><BoldFont>ServiceCode:</BoldFont> A unique identifier for the notification
                                    service. Reach out to our team if you’re unsure about
                                    what <BoldFont>{'{serviceCode}'}</BoldFont> you need to pass</ListItem>
                            </UnorderedList>
                            <Paragraph style={ {marginTop: '10px'} }>The API can return one of two values for the
                                service:</Paragraph>
                            <UnorderedList>
                                <ListItem><BoldFont>Consent: false</BoldFont>: The customer has not chosen
                                    to receive digital notifications for this service.
                                    For paperless notification types (e.g. Vehicle Registration reminders),
                                    this means they are have not chosen to receive digital notifications,
                                    but may still receive paper notifications via the post if they are eligible.
                                    For digital-only notifications, will not receive any notifications for this service.</ListItem>
                                <ListItem><BoldFont>Consent: true</BoldFont>: The customer is
                                    currently chosen to receive digital notifications for this service.
                                    For paperless notification types (e.g. Vehicle Registration reminders),
                                    this means they have chosen to receive these notifications digitally
                                    rather than via the post.</ListItem>
                            </UnorderedList>
                        </div>
                        {(error != null) ? <div><Paragraph>Error loading API docs: {error}</Paragraph></div>
                            : (swaggerSpec != null) ?
                                <SwaggerUIComponent swaggerSpec={ filterSpec('Preference Service') }/>
                                : <></>}
                        <HorizontalLine/>
                        <div id={ `${anchorMenuMetaData.data[15].id}` }><H4Heading>Help & technical support</H4Heading>
                        </div>
                        <div>
                            <Paragraph>For any technical support in setting up your notification API, reach out to the
                                digital notification
                            {/* eslint-disable-next-line max-len */}
                                engineers through our Slack channel #<BoldFont>notifications-support</BoldFont> or email
                                us <TextLink
                                href={ 'mailto:notifications@service.nsw.gov.au' }>notifications@service.nsw.gov.au</TextLink>.</Paragraph>
                        </div>
                    </ContentContainer>
                </ContentSection>
                <GapSection>&nbsp;</GapSection>
                <AnchorMenuSection>
                    <AnchorMenuComponent menuMetaData={ anchorMenuMetaData }/>
                </AnchorMenuSection>
            </BodyContainer>
        </CustomTableContainer>
    );
};

export default APIDocsComponent;